import { Box, Checkbox, CircularProgress, Grid, List, ListItem, ListItemIcon, ListItemText, ListSubheader } from '@material-ui/core'
import React, { useContext, useEffect, useState } from 'react'
import { UserContext } from '../../context/UserContext/context'

function LayerList({handleToggle, layerControlTitle, layers}) {

  const { user, dispatchUser } = useContext(UserContext)
  const [selectedClient, setSelectedClient] = useState(user?.details.selectedClient.id)

  // difficult problem to solve then index needs to be monitored as well to reset the state for now its hard coded due to there just being one layer

  useEffect(() => {
    if(user.details.selectedClient.id !== selectedClient ) handleToggle(false, 0)
  },[user])

  return (
    <div>
      <List subheader={
        <ListSubheader disableSticky component="div" id="nested-list-subheader">
          {layerControlTitle ? layerControlTitle : 'Layer control'} 
        </ListSubheader>
      }>
        {layers.map((value, index) => {
          return value?.display && (
            <div key={'layer-control-child-container-' + value.name}>
              <ListItem
                key={value.name}
                role={undefined}
                dense
              >
                <ListItemIcon>
                  {value?.loading ? 
                    <CircularProgress className={styles.loader}/> :
                    <Checkbox 
                      color='primary'
                      onClick={
                          (e) => {handleToggle(e.target.checked,index) }
                      }
                      edge="start"
                      checked={value.checked}
                      tabIndex={-1}
                      disableRipple
                      inputProps={
                          { 'aria-labelledby': value.displayName }
                      }
                    />
                  }
                </ListItemIcon>
                <Grid container style={{flexDirection: 'column', alignItems: 'start', paddingLeft: '0', paddingTop: '0' }}>
                  <ListItemText 
                    id={`checkbox-list-label-${value.name}`} 
                    primary={value.displayName} 
                    style={{textAlign: 'left'}}
                  />
                  <Grid item container direction='row'>
                    {value.colors.map((c, index) => {
                      return (<Box 
                        key={value.name + '_tab_' + c.name}
                        aria-label={c.name}
                        style={
                          {
                            backgroundColor: c.backgroundColor,
                            fontSize: '8pt',
                            fontWeight: 'bold',
                            color: 'rgb(26, 26, 26)',
                            padding: '4px 8px',
                            margin: '0 2px 6px 2px',
                            borderRadius: '4px',
                          }
                        }
                      >
                        {c.name}
                      </Box>)
                    })}
                  </Grid>
                </Grid>
              </ListItem>
            </div>
          )
        })}
      </List>
    </div>
  )
}

export default LayerList