import React, {useEffect, useState, useRef, useMemo} from 'react'

import {Grid, TextField, Typography} from '@material-ui/core'
import IconButton from '@material-ui/core/IconButton'
import Paper from '@material-ui/core/Paper'
import {makeStyles} from '@material-ui/core/styles'
import {
  LocationOn,
  MyLocation,
  Search,
  LocationSearching,
  LocationDisabled,
} from '@material-ui/icons'
import Autocomplete from '@material-ui/lab/Autocomplete'
import parse from 'autosuggest-highlight/parse'
import {throttle, isEqual} from 'lodash'
const useStyles = makeStyles((theme) => ({
  root: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    // minWidth: '300px',
    maxWidth: '500px',
    borderRadius: '4px'
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    height: 28,
    margin: 4,
  },
  icon: {
    color: theme.palette.text.secondary,
    marginRight: theme.spacing(2),
  },
}))
function loadScript(src, position, id) {
  if (!position) {
    return
  }
  const script = document.createElement('script')
  script.setAttribute('async', '')
  script.setAttribute('id', id)
  script.src = src
  position.appendChild(script)
}
function deepCompareEquals(a, b) {
  // TODO: implement deep comparison here
  // something like lodash
  return isEqual(a, b)
}
function useDeepCompareMemoize(value) {
  const ref = useRef()
  // it can be done by using useMemo as well
  // but useRef is rather cleaner and easier
  if (!deepCompareEquals(value, ref.current)) {
    ref.current = value
  }
  return ref.current
}
function useDeepCompareEffect(callback, dependencies) {
  // This is a custom hook that is interchangeable with useEffect
  // This hook does a deep comparison with the dependencies.
  // It mainly focusses on being able to deeply compare changes in objects/arrays
  useEffect(callback, dependencies.map(useDeepCompareMemoize))
}
const autocompleteService = {current: null}
export default function GoogleAutocompleteWidget(props) {
  const classes = useStyles()
  const [value, setValue] = useState(props.place)
  const [inputValue, setInputValue] = useState(null)
  const [options, setOptions] = useState([])
  const [sessionToken, setSessionToken] = useState('')
  const loadingLocationOnRef = useRef(false)
  const loaded = useRef(false)
  if (
    !props.noLoad &&
    typeof window !== 'undefined' &&
    !window.google &&
    !loaded.current
  ) {
    if (!document.querySelector('#google-maps')) {
      loadScript(
        `https://maps.googleapis.com/maps/api/js?key=${props.apikey}&libraries=places`,
        document.querySelector('head'),
        'google-maps',
      )
    }
    loaded.current = true
  } else if (!props.noLoad) {
    loaded.current = true
  }
  const fetch = useMemo(
    () =>
      throttle((request, callback) => {
        autocompleteService.current.getPlacePredictions(request, callback)
      }, 200),
    [],
  )
  useEffect((place) => {
    setValue(place)
  }, [])
  const updateSessionToken = () => {
    if (!window.google) return
    if (!sessionToken || sessionToken === '') {
      setSessionToken(new window.google.maps.places.AutocompleteSessionToken())
    }
  }
  useEffect(() => {
    // if(!useAutocomplete) return;
    let active = true
    updateSessionToken()
    if (!sessionToken || sessionToken === '') return
    if (!autocompleteService.current && window.google) {
      autocompleteService.current = new window.google.maps.places.AutocompleteService()
    }
    if (!autocompleteService.current) {
      return null
    }
    if (inputValue === '') {
      setOptions(value ? [value] : [])
      return null
    }
    let request = {input: inputValue, sessionToken: sessionToken}
    if (props.autocomplete && props.autocomplete) {
      Object.keys(props.autocomplete).forEach((key) => {
        request[key] = props.autocomplete[key]
      })
    }
    fetch(request, (results) => {
      if (active) {
        let newOptions = []
        if (value) {
          newOptions = [value]
        }
        if (results) {
          newOptions = [...newOptions, ...results]
        }
        setOptions(newOptions)
      }
    })
    return () => {
      active = false
    }
  }, [value, inputValue, fetch])
  const handleAddressChange = (address) => {
    if (address && address.place_id) {
      getPlaceDetails(address)
    } else if (props.handleAddressChange) {
      props.handleAddressChange(address)
    }
    setSessionToken('')
    // setUseAutocomplete(true);
  }
  const getPlaceDetails = (address) => {
    let service = new window.google.maps.places.PlacesService(
      document.createElement('div'),
    )
    try {
      let serviceDetails = service.getDetails(
        {
          placeId: address.place_id,
          sessionToken: sessionToken,
          fields:
            props.autocomplete && props.autocomplete.fields
              ? props.autocomplete.fields
              : ['address_component', 'formatted_address', 'geometry'], //default value if no fields are set
        },
        (place, status) => {
          if (props.handleAddressChange) props.handleAddressChange(place)
        },
      )
    } catch (err) {
      console.log(err)
    }
  }
  const handleGeolocate = (event) => {
    if (props.handleGeolocate) props.handleGeolocate()
  }
  function handleAutocompleteChange(event, newValue) {
    setOptions(newValue ? [newValue, ...options] : options)
    setValue(newValue)
    handleAddressChange(newValue)
  }
  function getOptionDisplayValue(option, value) {
    let currentOption =
      typeof option === 'string'
        ? option
        : option.description
        ? option.description
        : option.formatted_address
        ? option.formatted_address
        : ''
    return currentOption
  }
  useDeepCompareEffect(() => {
    if (!props.displayAddress) return
    let currentAddress = props.displayAddress
      ? props.displayAddress.formatted_address
      : props.displayAddress.description

    setInputValue(currentAddress)
    setValue(currentAddress)
  }, [props.displayAddress])


  return (
    <Paper component="form" className={classes.root}>
      <Autocomplete
        id={props.id ? props.id : 'pac-input'}
        className={classes.input}
        getOptionLabel={getOptionDisplayValue}
        filterOptions={(x) => x}
        options={options}
        autoComplete
        includeInputInList
        filterSelectedOptions
        value={value}
        freeSolo
        onChange={handleAutocompleteChange}
        onInputChange={(event, newInputValue) => {
          setInputValue(newInputValue)
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder={props.placeholder}
            fullWidth
            onKeyDown={(e) => {
              if (e.key === 'Enter' && e.target.value) {
                e.preventDefault()
              }
            }}
          />
        )}
        renderOption={(option) => {
          let matches
          let parts
          if (option && option.structured_formatting) {
            matches = option.structured_formatting.main_text_matched_substrings
            parts = parse(
              option.structured_formatting.main_text,
              matches.map((match) => [
                match.offset,
                match.offset + match.length,
              ]),
            )
          } else if (option && option.formatted_address) {
            matches = option.formatted_address
            parts = option.formatted_address
          }
          return (
            <Grid container alignItems="center">
              {/* <Grid item>
                <LocationOn className={classes.icon} />
              </Grid> */}
              <Grid item xs>
                {parts ? (
                  parts.map((part, index) => (
                    <span
                      key={index}
                      style={{fontWeight: part.highlight ? 700 : 400}}
                    >
                      {part.text ? part.text : part}
                    </span>
                  ))
                ) : (
                  <span key={option} style={{fontWeight: 700}}>
                    {option}
                  </span>
                )}
                <Typography variant="body2" color="textSecondary">
                  {option.structured_formatting
                    ? option.structured_formatting.secondary_text
                    : option}
                </Typography>
              </Grid>
            </Grid>
          )
        }}
      />
      <IconButton
        type="submit"
        className={classes.iconButton}
        aria-label="search"
        onClick={(e) => {
          e.preventDefault()
          handleAddressChange(inputValue)
        }}
      >
        <Search />
      </IconButton>
      {/* <Divider className={classes.divider} orientation="vertical" /> */}
    </Paper>
  )
}