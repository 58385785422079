import axios from "axios"

export async function logAction(user, action, message, info) {

    if (!user || !user.email) throw new Error('Invalid user')
    if (!action) throw new Error('Invalid action')

    let formattedMessage = {
      message: message,
    }

    if (info) formattedMessage = {
      ...formattedMessage,
      ...info,
    }

    if (!process.env.REACT_APP_AUDIT_LOGGING_API_URL || !process.env.REACT_APP_AUDIT_LOGGING_API_KEY) return

    const logData = {
        log_name: process.env.REACT_APP_AUDIT_LOGGING_API_FUNCTIONS_LOG_NAME,
        user: user.email,
        action,
        metadata: {
            ...formattedMessage
        },
    }

    try {
        const logResponse = await axios.post(
            `${process.env.REACT_APP_AUDIT_LOGGING_API_URL}audit/logs?key=${process.env.REACT_APP_AUDIT_LOGGING_API_KEY}`,
            logData
        )

        if (logResponse.status === 200) {
            // eslint-disable-next-line consistent-return
            return {
                success: true,
                code: 200,
                message: 'Ok',
            }
        }

        // eslint-disable-next-line consistent-return
        return {
            success: false,
            code: logResponse.status,
            message: 'Oops something went wrong',
        }

    } catch (e) {
        console.error('Audit Logging error: ', e.message)
        logError('Audit Logging error ', e)

        // eslint-disable-next-line consistent-return
        return {
            success: false,
            code: 500,
            message: 'Oops something went wrong',
        }
    }

}

export async function logError(message, err) {
    console.error(message, err)
}

export const auditLog = async (request, response, user) => {
  if (!request) return
  let cleanResponse = {results: []}

  if(!response.results) 
    cleanResponse = {results: [...response.results]}


  const usrRequest = JSON.parse(JSON.stringify(request))
  let customMetadata

  if (request.metadata) {
    customMetadata = JSON.parse(JSON.stringify(request.metadata))
    delete request.metadata
  }

  const logData = {
    log_name:
      request.log_name ?? process.env.VITE_AUDIT_LOGGING_API_TOOL_LOG_NAME,
    user: user || '',
    action: request.action ?? 'campaing-manager-feasibility',
    metadata: {
      request: { ...usrRequest },
      response: { ...response }
    },
  }

  if (cleanResponse?.results?.length) {
    if (Array.isArray(cleanResponse.results) && !cleanResponse.results.length) {
      cleanResponse.results = []
    } else if (Array.isArray(cleanResponse.results) && cleanResponse.results[0]?.Feasible === 'Not Feasible') {
      cleanResponse.results = []
    }
    logData.metadata.response = { ...cleanResponse }
  }

  if (customMetadata)
    logData.metadata = { ...logData.metadata, ...customMetadata }

  try {
    const logResponse = await fetch(
      `${process.env.VITE_AUDIT_LOGGING_API_URL}audit/logs?key=${process.env.VITE_AUDIT_LOGGING_API_KEY}`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json;charset=utf-8'
        },
        body: JSON.stringify(logData),
      }
    )
    const logResponseJson = await logResponse.json()

    if (logResponseJson.status === 200){
      // eslint-disable-next-line consistent-return
      return {
        success: true,
        code: 200,
        message: 'Ok',
      }
    }

      // eslint-disable-next-line consistent-return
    return {
        success: false,
        code: logResponseJson.status,
        message: 'Oops something went wrong',
      }

  } catch (e) {
    console.error('Audit Logging error: ', e.message)

      // eslint-disable-next-line consistent-return
    return {
      success: false,
      code: 500,
      message: 'Oops something went wrong',
    }
  }
}

