import { useState } from 'react'

import { Collapse, Container, Divider, FormControl, FormControlLabel, FormGroup, Grid, ListSubheader, Paper, Switch, makeStyles } from '@material-ui/core'

import Filter from './Filter'
import LayerList from './LayerList'

const useStyles = makeStyles((theme) => ({
  root: {
    // width: '100%',
    // maxWidth: '300px',
    zIndex: 100,
    margin: '0px',
    // overflow: 'auto',
    borderRadius: '0 4px 4px 0 !important'
  },
  settings: {
    color: '#7d7d7d',
    fontSize: '8pt !important',
    margin: '4px 0',
    paddingLeft: '8px !important',
    paddingRight: '8px !important',
  },
  layers: {
    paddingLeft: '8px !important',
    paddingRight: '8px !important',
    paddingBottom: '8px',
  },
  filter: {
    paddingLeft: '8px !important',
    paddingRight: '10px !important',
    width: '100%'
  }

}))

function CoverageFilter({ layerControlTitle, layers, selectedService, data, onProviderChange, onStatusChange, onServiceChange, handleToggle }) {

  const styles = useStyles()

  const [displayFilter, setDisplayFilter] = useState(true)

  return (
      <Grid className={styles.root}  container direction='column'>
        <Grid item>
          <Divider />
        </Grid>
        <Grid item container direction='column'>
          <Collapse in={displayFilter}>
            <Grid item className={styles.filter}>
              {data &&
                <Filter
                  data={data}
                  selectedService={selectedService}
                  onServiceChange={onServiceChange}
                  onStatusChange={onStatusChange}
                  onProviderChange={onProviderChange}
                />
              }
            </Grid>
          </Collapse>
        </Grid>
        <Grid item>
          <Divider />
        </Grid>
        <Grid item container className={styles.layers}>
          {layers &&
            <LayerList
              layers={layers}
              layerControlTitle={layerControlTitle}
              handleToggle={handleToggle}
            />
          }
        </Grid>
      </Grid>
  )
}

export default CoverageFilter