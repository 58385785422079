import React, { useEffect, useRef, useState } from 'react'

import CssBaseline from '@material-ui/core/CssBaseline'
import { makeStyles } from '@material-ui/core/styles'
import { center } from '@turf/turf'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  mapStyle: {
    width: '100%',
    height: '100%',
  },
  attr: {
    width: '60px',
  },
  attrLink: {
    position: 'absolute',
    bottom: '20px',
    marginLeft: '8px',
    zIndex: '1000'
  }
}))

export default function GoogleMap(props) {
  const classes = useStyles()
  const googleMapRef = useRef(null)
  const [map, setMap] = useState(null)
  const [autocomplete, setAutocomplete] = useState(null)

  useEffect(() => {
    //   props.setIsLoading(true)
    let libraries = 'places'
    if (props.libraries && props.libraries.length > 0) {
      libraries = props.libraries.join(',')
    }
    if (!window.google) {
      const script = document.createElement('script')
      script.type = 'text/javascript'
      script.src = `https://maps.google.com/maps/api/js?libraries=${libraries}&key=${props.apikey}&map_ids=a45c6f175749da0d`
      script.id = 'google-maps'
      document.body.appendChild(script)

      script.addEventListener('load', (e) => {
        onScriptLoad()
      })

      if (props.addMapClickListener)
        script.addEventListener('click', (e) => {
          if (props.onClick) props.onClick(e.latLng.lat(), e.latLng.lng())
        })
    } else {
      onScriptLoad()
    }
  }, [])

  const onScriptLoad = () => {
    if(!window.google) return
    let options = {}
    if (props.options && props.options.map) options = props.options.map
    options.mapTypeControlOptions = {
      position: window.google.maps.ControlPosition.RIGHT_TOP,
      style: window.google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
      mapTypeIds: [
        window.google.maps.MapTypeId.ROADMAP,
        window.google.maps.MapTypeId.HYBRID,
        window.google.maps.MapTypeId.TERRAIN,
        window.google.maps.MapTypeId.SATELLITE,
      ],
    }
    options.zoomControlOptions = {
      position: window.google.maps.ControlPosition.RIGHT_CENTER,
    }

    const newMap = new window.google.maps.Map(googleMapRef.current, options)
    if (props.handleMapClick) {
      window.google.maps.event.addListener(newMap, 'click', function (event) {
        // drop pin when user clicks on map
        if (props.handleMapClick) {
          props.handleMapClick(event.latLng.lat(), event.latLng.lng())
        }
      })
    }

    setMap(newMap)

    window.google.maps.event.addListener(newMap, 'bounds_changed', function () {
      if(props && props.setIsLoading) props.setIsLoading(true)
      window.google.maps.event.addListenerOnce(newMap, 'tilesloaded', function () {
        if(props && props.setIsLoading) props.setIsLoading(false)
      })
    })

    window.google.maps.event.addListener(newMap, 'idle', function (event) {
      if(props && props.setIsLoading) props.setIsLoading(false)
    })

    addAdditionalFeatures()

    if (props.onMapLoad) props.onMapLoad(newMap)
  }

  const addAdditionalFeatures = () => {

    google.maps.Polygon.prototype.getBounds = function () {
      var bounds = new google.maps.LatLngBounds();
      var paths = this.getPaths();
      var path;
      for (var i = 0; i < paths.getLength(); i++) {
        path = paths.getAt(i);
        for (var ii = 0; ii < path.getLength(); ii++) {
          bounds.extend(path.getAt(ii));
        }
      }
      return bounds;
    }

    window.google.maps.Rectangle.prototype.getGeoJSON = function () {
      let geoJSON = {
        type: "Polygon",
        coordinates: []
      }

      let bounds = this.getBounds()
      let ne = [getNorthEast().lng(), getNorthEast().lat()]
      let nw = [bounds.getSouthWest().lng(), getNorthEast().lat()]
      let sw = [bounds.getSouthWest().lng(), getSouthWest().lat()]
      let se = [getNorthEast().lng(), getSouthWest().lat()]

      geoJSON.coordinates.push([ne, nw, sw, se, ne])
      return {
        "type": "Feature",
        "geometry": geoJSON,
        "properties": {
          "geometry_type": 'rectangle'
        }
      }
    }

    window.google.maps.Circle.prototype.getGeoJSON = function () {
      let geoJSON = {
        type: "Polygon",
        coordinates: []
      }

      let firstPoint = false
      let point = this.getCenter()
      let radius = this.getRadius()
      let verts = []

      let d2r = Math.PI / 180 // degrees to radians
      let r2d = 180 / Math.PI // radians to degrees
      radius = radius / 1609 // meters to miles
      let earthsradius = 3963 // 3963 is the radius of the earth in miles
      let num_seg = 32 // number of segments used to approximate a circle
      let rlat = (radius / earthsradius) * r2d
      let rlng = rlat / Math.cos(point.lat() * d2r)

      for (let n = 0; n < num_seg; n++) {

        let theta = Math.PI * (n / (num_seg / 2))
        let lng = point.lng() + (rlng * Math.cos(theta)) // center a + radius x * cos(theta)
        let lat = point.lat() + (rlat * Math.sin(theta)) // center b + radius y * sin(theta)
        verts.push([lng, lat])

        if (firstPoint === false) {
          firstPoint = [lng, lat]
        }
      }

      if (firstPoint && verts.length > 1 && verts[verts.length - 1][0] != firstPoint[0] && verts[verts.length - 1][1] != firstPoint[1])
        verts.push(firstPoint)

      geoJSON.coordinates.push(verts)
      return {
        "type": "Feature",
        "geometry": geoJSON,
        "properties": {
          "geometry_type": 'circle',
          "radius": radius,
          "center": center
        }
      }
    }

    window.google.maps.Polygon.prototype.getGeoJSON = function () {
      let geoJSON = {
        type: "Polygon",
        coordinates: []
      }

      let paths = this.getPaths().getArray()

      for (let path of paths) {
        let pathArray = []
        let points = path.getArray()
        let firstPoint = false

        for (let point of points) {
          if (firstPoint === false) {
            firstPoint = point
          }

          pathArray.push([point.lng(), point.lat()])
        }

        if (firstPoint && pathArray.length > 1 && pathArray[pathArray.length - 1][0] != firstPoint.lng() && pathArray[pathArray.length - 1][1] != firstPoint.lat())
          pathArray.push([firstPoint.lng(), firstPoint.lat()])

        geoJSON.coordinates.push(pathArray)
      }

      let feature = {
        "type": "Feature",
        "geometry": geoJSON,
        "properties": {
          "geometry_type": 'polygon'
        }
      }

      if(this.properties) {
        feature.properties = this.properties
      }
      if(this.attributes) {
        feature.attributes = this.attributes
      }
      if(this.style) {
        feature.style = this.style
      }
      if(this.ui_config) {
        feature.ui_config = this.ui_config
      }
      // if(this.properties) {
      //   let props = {"geometry_type": 'polygon'}
      //   if (this.properties.details && this.properties.details.name) props['name'] = this.properties.details.name.value
      //   if (this.properties.details && this.properties.details.active) props['active'] = this.properties.details.active.value
      //   if (this.properties.details && this.properties.details.category) props['category'] = this.properties.details.category.value
      //   if ()

      //   feature.properties = {...props}
      // }
      // if(this.attributes) {
      //   this.attributes
      // }

      return feature
    }
  }

  const renderChildren = () => {
    const { children } = this.props
    if (!children) return

    return React.Children.map(children, c => {
      return React.cloneElement(c, {
        map: map,
        google: window.google,
        // mapCenter: this.state.currentLocation
      })
    })
  }

  return (
    <>
      <a href='https://www.28east.co.za/' className={classes.attrLink} rel="noreferrer" title='Powered by 28East' attr='Powered by 28East' target='_blank'>
        <img id='28East-attribution' className={classes.attr} src='28east.png' />
      </a>
      <div id='google-map-container' className={classes.mapStyle} ref={googleMapRef} />
      <div className={classes.content}>
        {
          /* {renderChildren()} */
          props.children
        }
      </div>
    </>
  )
}
