import React, { useContext } from 'react'

import Avatar from '@material-ui/core/Avatar'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import Container from '@material-ui/core/Container'
import CssBaseline from '@material-ui/core/CssBaseline'
import { makeStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import LockOutlinedIcon from '@material-ui/icons/LockOutlined'

import Navbar from '../components/Navbar'
import { BrandContext } from '../context/BrandContext/context'
import { USER, UserContext } from '../context/UserContext/context'
import { SET_USER } from '../context/UserContext/types'
import { logAction } from '../services/Logging'
import firebase from 'firebase/app'

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        height: '100vh',
    },
    paper: {
        marginTop: theme.spacing(15),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        // margin: theme.spacing(3, 0, 2),
        marginBottom: theme.spacing(1),
    },
    googleButton: {
        color: '#fff',
        backgroundColor: '#de5246',
        marginBottom: theme.spacing(1),
    },
    error: {
        color: theme.palette.error.light,
    },
    wrapper: {
        margin: theme.spacing(3, 0, 2),
        // margin: theme.spacing(1),
        position: 'relative',
        textAlign: 'center'
    },
    buttonProgress: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -8,
        marginLeft: -12,
    },
}))

export default function LoginView(props) {
    const { brand } = useContext(BrandContext)
    const { user, dispatchUser } = useContext(UserContext)
    const classes = useStyles()

    const firebaseApp =
        brand.authentication && !firebase.apps.length
            ? firebase.initializeApp(brand.firebaseConfig)
            : brand.authentication
                ? firebase.app()
                : null // firebase.initializeApp(brand.firebaseConfig);
    // const firebaseAppAuth = brand.authentication ? firebaseApp.auth() : null
    const [state, setState] = React.useState({
        email: '',
        password: '',
    })

    const handleSignIn = (event) => {
        event.preventDefault()
        onLogin(state.email, state.password)
    }

    const handleChange = (name) => (event) => {
        setState({ ...state, [name]: event.target.value })
    }

    const onLogin = (email, password) => {
        // setLoading(true)
        // setLoginErrorMessage('')
        dispatchUser({
            type: SET_USER,
            payload: {
                ...user,
                isVerifying: true,
                error: null
            }
        })
        if (brand.authentication) {
            firebase
                .auth()
                .signInWithEmailAndPassword(email, password)
                .catch(function (error) {
                    let { message } = error
                    if (error.code === 'auth/user-not-found' || error.code === 'auth/wrong-password')
                        message = 'Invalid email or password provided.'

                    // setLoading(false)
                    // setLoginErrorMessage(message)
                    handleAuthError(message)
                })
        }
    }

    const onGoogleLogin = () => {
        // setLoading(true)
        // setLoginErrorMessage('')
        handleUserVerifying()
        if (brand.authentication) {
            let googleAuthProvider = new firebase.auth.GoogleAuthProvider()
            firebase
                .auth()
                .signInWithPopup(googleAuthProvider)
                .catch((error) => {
                    let { message } = error
                    if (error.code === 'auth/user-not-found' || error.code === 'auth/wrong-password')
                        message = 'Invalid email or password provided.'

                    // setLoading(false)
                    // setLoginErrorMessage(message)
                    handleAuthError(message)
                })
        }

    }

    const handleUserVerifying = (message) => {
        // setLoading(false)
        // setLoginErrorMessage(message)

        dispatchUser({
            type: SET_USER,
            payload: {
                isAuthenticated: false,
                isAuthorized: false,
                isVerifying: true,
                details: null,
                error: null
            }
        })
    }

    const handleAuthError = (message) => {

        // setLoading(false)
        // setLoginErrorMessage(message)

        dispatchUser({
            type: SET_USER,
            payload: {
                isAuthenticated: false,
                isAuthorized: false,
                isVerifying: false,
                details: null,
                error: message
            }
        })
    }


    return (
        <div className={classes.root}>
            {brand.navbar.show && <Navbar />}
            <Container component="main" maxWidth="xs">
                <CssBaseline />
                <div className={classes.paper}>
                    <Avatar className={classes.avatar}>
                        <LockOutlinedIcon />
                    </Avatar>
                    <Typography component="h1" variant="h5">
                        Sign in
                    </Typography>
                    <Typography component="div">
                        <Box className={classes.error} fontSize="fontSize" m={1}>
                            {user.error ? user.error : ''}
                        </Box>
                    </Typography>
                    <form action="/" method="post" className={classes.form} onSubmit={handleSignIn}>
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            id="email"
                            type="email"
                            label="Email Address"
                            name="email"
                            autoComplete="email"
                            onChange={handleChange('email')}
                            autoFocus
                        />
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            name="password"
                            label="Password"
                            type="password"
                            id="password"
                            autoComplete="current-password"
                            onChange={handleChange('password')}
                        />
                        <div className={classes.wrapper}>
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                color="primary"
                                className={classes.submit}
                                disabled={user.isVerifying}
                            >
                                Sign In
                            </Button>
                            <Button
                                type="button"
                                fullWidth
                                variant="contained"
                                color="secondary"
                                className={classes.googleButton}
                                disabled={user.isVerifying}
                                onClick={onGoogleLogin}
                            >
                                Sign In With Google
                            </Button>
                            {user.isVerifying && <CircularProgress size={24} className={classes.buttonProgress} />}
                        </div>
                    </form>
                </div>
            </Container>
        </div>
    )
}
